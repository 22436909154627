.main-slider {
  overflow-x: hidden;
  overflow-y: hidden;
  height: 670px;
  width: 100vw;
}

@media screen and (max-width: 768px) {
  .main-slider {
    height: 450px;
  }
}
@media screen and (max-width: 550px) {
  .main-slider {
    height: 400px;
  }
}

.slider-parts-heading-1 {
  font-size: 50px;
  font-weight: 600;
  color: white;
  line-height: 60px;
}
@media screen and (max-width: 768px) {
  .slider-parts-heading-1 {
    width: min-content;
    font-size: 35px;
    font-weight: 600;
    color: white;
    line-height: 40px;
  }
}
@media screen and (max-width: 550px) {
  .slider-parts-heading-1 {
    /* width: min-content; */
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
  }
}

.slider-parts-heading-2 {
  font-size: 50px;
  font-weight: 600;
  color: gold;
}

@media screen and (max-width: 768px) {
  .slider-parts-heading-2 {
    font-size: 35px;
    font-weight: 600;
  }
}
@media screen and (max-width: 550px) {
  .slider-parts-heading-2 {
    font-size: 20px;
    font-weight: 600;
  }
}
.slider-parts {
  position: absolute;
  left: 10%;
  top: 20%;
}
@media screen and (max-width: 768px) {
  .slider-parts {
    position: absolute;
    left: 10%;
    top: 10%;
  }
}

.slider-search {
  max-width: 580px;
  width: 100%;
  background-color: white;
  margin-top: 2rem;
}
@media screen and (max-width: 768px) {
  .slider-search {
    margin-top: 0.5rem;
  }
}

.slider-search input {
  width: 90%;
  padding: 0.5rem;
  outline-style: none;
}

.slider-btns .btn {
  width: 150px;
  padding: 0.5rem 0.2rem;
  font-size: 16px;
  border-radius: 3px;
  font-weight: 600;
}
.slider-btns {
  margin-top: 2.5rem;
}
.btn-left {
  color: inherit;
  background-color: white;
  border: 2px solid white;
  margin-right: 20px;
}
.btn-right {
  color: white;
  border: 2px solid white;
  margin-left: 20px;
}

.timer-silder {
  width: 100%;
  background-color: #002147;
  height: 160px;
  border: 1px solid grey;
  border-radius: 15px;
  margin-top: -4rem;
  padding: 0.5rem;
}
.timer-slider-img {
  margin: 1rem 0.5rem 1rem 2rem;
}
@media screen and (max-width: 768px) {
  .timer-slider-img {
    margin: 0.25rem 0.25rem 0.25rem 0.5rem;
  }
}

.timer-slider-img img {
  height: 135px;
}

.text-gold {
  color: #fdc800;
}

.heading-1 {
  font-size: 40px;
  font-weight: 600;
  color: #002147;
}
.heading-3 {
  font-size: 25px;
  font-weight: 600;
  color: #002147;
}
.courses-card-heading {
  font-size: 20px;
  font-weight: 600;
  color: #002147;
}

.courses-card {
  background-color: rgb(245, 245, 245);
  height: 150px;
  border-radius: 10px;
  /* box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; */
}

.question-main {
  background-image: url("./assets/Mask\ Group\ 2.png");
  height: 600px;
  width: 100%;
  background-size: cover;
  border-radius: 10px;
}

.question-main .heading-1 {
  padding: 50px;
}

.footer-wrapper {
  background-color: #002147;
}

.footer ul li {
  color: white;
}

.footer-heading {
  color: white;
  font-size: 20px;
  font-weight: 600;
}
.footer-bottom {
  background-color: #fdc800;
}
.subscribe-wrapper {
  background-color: whitesmoke;
}
.subscribe-wrapper h1 {
  font-size: 60px;
  margin-left: 20px;
  font-weight: 600;
  line-height: 65px;
}

.subscribe-input {
  background-color: white;
  border: 2px solid lightgrey;
  border-radius: 10px;
  margin: 20px;
}
.subscribe-input input {
  padding: 0.5rem;
  outline-style: none;
  border-radius: 10px;
}
.subscribe-input button {
  padding: 0.5rem;
  outline-style: none;
  border-radius: 10px;
  /* color:white; */
  background-color: #fdc800;
  margin: 0.2rem;
  font-size: 14px;
}

.alice-carousel__next-btn-item {
  position: relative;
  top: -265px;
  background-color: white;
  left: 498px;
  border-radius: 50px;
  height: 50px;
  width: 50px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.alice-carousel__prev-btn-item {
  position: relative;
  top: -265px;
  background-color: white;
  left: -530px;
  border-radius: 50px;
  height: 50px;
  width: 50px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.profile-banner {
  background: #14428f;
}

.profile-name {
  font-size: 2rem;
  color: white;
  text-transform: uppercase;
}
.profile-image img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}
.navbar-main {
  background: #14428f;
  /* background: linear-gradient(90deg, rgba(21,3,140,1) 0%, rgba(0,33,71,1) 100%); */
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #c9c7c7;
}
.editor :global(.public-DraftEditor-content) {
  max-height: 250px;
  overflow: auto;
}

.hide-form {
  display: none;
}

.is-drop:hover .hide-form {
  display: block;
}
.upload {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 16rem;
  color: #fff;
  font-family: "Montserrat";
  background-color: #4aa0ea;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.upload p {
  letter-spacing: 0.1em;
  text-transform: uppercase;
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
}
.upload.is-highlight {
  background-color: rgba(91, 145, 192, 0.5);
}
.upload.is-highlight p {
  opacity: 1;
}
.upload.is-drop p {
  opacity: 0;
}
.upload-button {
  width: 200px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.upload-file {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  width: 100%;
  height: 50px;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: none;
  background-color: #101010;
  border: 0;
}
.my-form {
  width: 100%;
  /* display: none; */
}
.dropzone-file-upload {
  background-color: rgba(0, 0, 0, 0.35);
}

.dropzone-file-upload-main {
  color: white !important;
  position: absolute;
  bottom: 10px;
  cursor: pointer;
}
.dropzone-file-upload-main:hover {
  color: white !important;
  position: absolute;
  bottom: 50px;
  transition: 0.3s ease-in-out;
}
.dropzone-file-upload-main:hover .dropzone-file-upload-hover {
  color: white !important;
}

.profile-image-wrapper .profile-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #14428f transparent #14428f transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rdw-storybook-root {
  margin: 50px;
  width: 90%;
}
.rdw-storybook-wrapper {
}
.rdw-storybook-editor {
  border: 1px solid #f1f1f1;
  padding: 5px;
  border-radius: 2px;
  height: 400px;
}
.rdw-storybook-toolbar {
  top: -130px;
}
.rdw-storybook-textarea {
  margin-top: 20px;
  resize: none;
  width: 100%;
  border: 1px solid #f1f1f1;
  height: 400px;
}
